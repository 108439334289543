import { colors } from '@/styles/GlobalStyle';
import styled from 'styled-components';

interface IMenuMobileContainer {
  isOpen: boolean;
}

export const BurguerMenuContainer = styled.div<IMenuMobileContainer>`
  z-index: 100;
  display: none;
  display: ${(props) => (props.isOpen ? 'block' : 'none')};
  width: 40px;
  height: 28px;
  position: relative;
  transform: rotate(0deg);
  transition: 0.5s ease-in-out;
  cursor: pointer;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;

  span {
    display: block;
    position: absolute;
    height: 4px;
    width: 100%;
    background: ${colors.gray800};
    transition: 0.3s;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: 0.25s ease-in-out;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -webkit-transition: 0.25s ease-in-out;
    -moz-transition: 0.25s ease-in-out;
    -o-transition: 0.25s ease-in-out;
  }
  /* Icon 3 */
  span:nth-child(1) {
    top: 3px;
  }
  span:nth-child(2),
  span:nth-child(3) {
    top: 13px;
  }
  span:nth-child(4) {
    top: 23px;
  }
  &.open span:nth-child(1) {
    top: 18px;
    width: 0%;
    left: 50%;
  }
  &.open span:nth-child(2) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  &.open span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  &.open span:nth-child(4) {
    top: 18px;
    width: 0%;
    left: 50%;
  }
  @media (max-width: 1040px) {
    display: block;
  }
`;

export const MenuMobileContainer = styled.div`
  display: none;
  height: 100%;
  .top {
    display: flex;
    justify-content: flex-end;
  }
  @media (max-width: 1040px) {
    display: flex;
  }
`;

export const Menu = styled.nav<{ isOpen: boolean }>`
  display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  flex-direction: column;
  position: absolute;
  background: transparent;
  width: 100%;
  top: 100%;
  height: 100vh;
  padding-bottom: 120px;
  background: white;
  left: 0;
  overflow-y: auto;
  z-index: 500;
  ::-webkit-scrollbar {
    display: none;
  }

  @keyframes fadeIn {
    0% {
      transform: translateY(-20px);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }

  animation: fadeIn 0.5s;

  hr {
    width: 100%;
    background-color: #d7dee5;
    color: #d7dee5;
    height: 1px;
    margin: 0;
    margin-top: 22px;
    border: none;
    outline: none;
  }
  @media (min-width: 1040px) {
    display: none;
  }
`;

export const MenuMobileLink = styled.a<{ selected?: boolean }>`
  font-size: 24px;
  font-weight: bold;
  background: transparent;

  display: flex;
  justify-content: space-between;
  margin-top: 22px;
  width: 100%;
  font-family: 'Sora';
  color: ${({ selected }) => (selected ? colors.orange300 : colors.gray800)};

  svg {
    color: ${colors.blue};
    transform: rotate(-90deg);
    margin-right: -4px;
  }

  transition: color 0.2s;

  padding: 0 40px;
  @media (max-width: 499px) {
    padding: 0 16px;
  }
`;
