import React from 'react';
import Head from 'next/head';

import { SITE_URL } from '@/config';

interface ISEO {
  title: string;
  description: string;
  image?: string;
}

const SEO = ({
  title,
  description,
  image = '/og-image.png',
}: ISEO): JSX.Element => {
  const seo = {
    title: title || 'Delps',
    description: description || 'Delps',
    image: `${SITE_URL}${image}`,
  };

  return (
    <Head>
      <title>{title + ' · Delps'}</title>
      <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      {seo.title && (
        <meta property="og:title" content={seo.title + ' · Delps'} />
      )}
      <meta name="description" content={seo.description} />
      {seo.description && (
        <meta property="og:description" content={seo.description} />
      )}
      <meta name="image" content={seo.image} />
      {seo.image && <meta property="og:image" content={seo.image} />}
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta property="og:locale" content="pt_BR" />
      <meta property="og:type" content="website" />
      <meta charSet="utf-8" />
      <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
      <meta name="keywords" content="Delps" />
      <meta name="twitter:card" content="summary_large_image" />
      {seo.title && <meta name="twitter:title" content={seo.title} />}
      {seo.description && (
        <meta name="twitter:description" content={seo.description} />
      )}
      {seo.image && <meta name="twitter:image" content={seo.image} />}
      <meta name="author" content="Hyerdev" />
    </Head>
  );
};

export default SEO;
