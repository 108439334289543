import FooterBottom from './FooterBottom';
import FooterTop from './FooterTop';

import { FooterContainer } from './styles';

const Footer = (): JSX.Element => {
  return (
    <FooterContainer>
      <FooterTop />
      <FooterBottom />
    </FooterContainer>
  );
};

export default Footer;
