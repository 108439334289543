import { colors } from '@/styles/GlobalStyle';
import styled from 'styled-components';

interface IMenuMobileContainer {
  isOpen: boolean;
}

export const AppsGridMobile = styled.div<IMenuMobileContainer>`
  padding: 12px;
  margin: 0 -12px;
  display: none;
  background: ${({ isOpen }) => (isOpen ? colors.orange300 : 'none')};

  border-radius: 50%;
  cursor: pointer;

  svg {
    color: ${({ isOpen }) => (isOpen ? 'white' : colors.gray700)};
    animation: ${({ isOpen }) =>
      isOpen ? 'color-animation 0.3s ease-in-out' : 'none'};
  }

  &:hover {
    svg {
      color: ${({ isOpen }) => (isOpen ? 'white' : colors.orange300)};
      transition: 0.2s;
    }

    background: ${({ isOpen }) => (isOpen ? colors.orange300 : colors.gray100)};

    transition: 0.2s;
  }

  @keyframes bg-animation {
    from {
      background: ${colors.gray100};
    }

    to {
      background: ${colors.orange300};
    }
  }

  @keyframes color-animation {
    from {
      color: ${colors.gray700};
    }

    to {
      color: white;
    }
  }

  animation: ${({ isOpen }) =>
    isOpen ? 'bg-animation 0.5s ease-in-out' : 'none'};

  @media (max-width: 1040px) {
    display: flex;
  }
`;

export const MenuMobileContainer = styled.div`
  display: none;
  height: 100%;
  .top {
    display: flex;
    justify-content: flex-end;
  }
  @media (max-width: 1040px) {
    display: flex;
  }
`;

export const Menu = styled.nav<{ isOpen: boolean }>`
  display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  flex-direction: column;
  position: absolute;
  background: transparent;
  width: 100%;
  top: 100%;
  height: 100vh;
  padding-bottom: 120px;
  background: white;
  left: 0;
  overflow-y: auto;
  z-index: 500;
  ::-webkit-scrollbar {
    display: none;
  }

  @keyframes fadeIn {
    0% {
      transform: translateY(-20px);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }

  animation: fadeIn 0.5s;

  hr {
    width: 100%;
    background-color: #d7dee5;
    color: #d7dee5;
    height: 1px;
    margin: 0;
    margin-top: 22px;
    border: none;
    outline: none;
  }
  @media (min-width: 1040px) {
    display: none;
  }
`;

export const MenuMobileLink = styled.a<{ selected?: boolean }>`
  font-size: 24px;
  font-weight: bold;
  background: transparent;

  display: flex;
  align-items: center;
  margin-top: 22px;
  gap: 20px;
  width: 100%;
  font-family: 'Sora';
  color: ${({ selected }) => (selected && colors.orange300) || colors.gray800};

  svg,
  g,
  path {
    color: ${colors.gray800};
    fill: ${({ selected }) => (selected && colors.orange300) || colors.gray800};
    margin-right: -4px;
  }

  transition: color 0.2s;

  padding: 0 40px;
  @media (max-width: 499px) {
    padding: 0 16px;
  }
`;
