const Rastreamento = (): JSX.Element => (
  <svg
    id="Inspection"
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="39.99"
    viewBox="0 0 40 39.99"
  >
    <path
      id="Caminho_11463"
      data-name="Caminho 11463"
      d="M16.285,32.468c-5.413-3.614-5.254-3.7-5.829-3.373S10,29.437,10,34.825c0,.608-.1.383,5.371,4.022a.833.833,0,0,0,.833.042c.591-.308.441-.341.441-5.729A.833.833,0,0,0,16.285,32.468Zm-1.291,4.164-3.331-2.223V31.386l3.331,2.223Z"
      transform="translate(-1.646 -4.83)"
      fill="#fff"
    />
    <path
      id="Caminho_11464"
      data-name="Caminho 11464"
      d="M39.724,36.878l-4.255-4.255a5.829,5.829,0,0,0-.5-7.553V9.989c0-.716.774-.058-17.087-9.884-.566-.316.65-.791-17.487,9.16a.891.891,0,0,0-.4.724H0C0,30.608-.18,29.508.4,29.858c17.6,10.559,16.721,10.109,17.079,10.109s-.217.283,10.134-5.954a5.829,5.829,0,0,0,6.662-.208c4.563,4.547,4.38,4.5,4.855,4.5a.833.833,0,0,0,.591-1.424Zm-15.58-2.74V31.257l1.016-.683a5.829,5.829,0,0,0,1.157,2.282Zm.833-5.479c-2.248,1.541-2.5,1.566-2.5,2.148v4.355l-4.164,2.5v-17.2l4.164-2.5c0,3.922-.133,4.072.391,4.4a.833.833,0,0,0,.833,0c5.363-2.656,5.438-2.515,5.438-3.206V13.961l4.164-2.5V23.888a5.829,5.829,0,0,0-8.327,4.8ZM1.66,11.463l14.989,8.993v17.2L1.66,28.667ZM12.669,4.427l14.156,8.96L23.311,15.5,9.088,6.392ZM24.144,16.959l3.331-1.974v3.672l-3.331,1.665ZM17.482,1.779,32.471,9.989,28.466,12.4,14.31,3.477ZM7.431,7.308l14.3,9.16-4.247,2.54L2.493,9.989ZM26.642,29.142a4.164,4.164,0,1,1,4.164,4.164A4.164,4.164,0,0,1,26.642,29.142Z"
      transform="translate(0.03 0.02)"
      fill="#fff"
    />
  </svg>
);

export default Rastreamento;
