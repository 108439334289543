import styled from 'styled-components';

import { colors } from '@/styles/GlobalStyle';

export const FooterContainer = styled.footer`
  width: 100%;
  max-width: 100vw;

  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  background: ${colors.gray800};
`;
