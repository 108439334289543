import styled from 'styled-components';

import { colors } from '@/styles/GlobalStyle';

export const HeaderContainer = styled.header`
  width: 100%;
  height: 81px;

  display: flex;
  justify-content: center;
  align-items: center;

  background: white;

  position: fixed;
  z-index: 10;

  border-bottom: 1px solid #d7dee5;
`;

export const HeaderWrapper = styled.div`
  width: 100%;
  max-width: 1360px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 1440px) {
    padding: 0 40px;
  }

  @media (max-width: 499px) {
    padding: 0 16px;
  }
`;

export const HeaderLeft = styled.nav`
  display: flex;
  align-items: space-between;

  > ul {
    margin-left: 40px;
    display: flex;
    align-items: center;

    gap: 32px;

    a {
      font-family: 'Sora', sans-serif;
      color: ${colors.gray700};
      text-decoration: none;

      &:hover {
        color: ${colors.orange300};
        transition: 0.2s;
      }
    }

    li {
      cursor: pointer;
      z-index: 600;
    }
  }

  @media (max-width: 1040px) {
    > ul {
      display: none;
    }
  }
`;

export const HeaderItem = styled.li<{ selected?: boolean }>`
  font-family: 'Sora', sans-serif;
  font-size: 15px;
  font-weight: 400;
  color: ${colors.gray700};
  text-decoration: none;

  display: flex;
  align-items: center;

  @keyframes border-animation {
    from {
      box-shadow: none;
    }

    to {
      box-shadow: 0px 2px 0px ${colors.orange300};
    }
  }

  animation: ${({ selected }) => (selected ? `border-animation 0.3s` : 'none')};
  box-shadow: ${({ selected }) =>
    selected ? `0px 2px 0px ${colors.orange300}` : 'none'};

  svg {
    color: ${colors.gray300};
  }

  a {
    svg {
      color: ${colors.gray700};
    }
  }

  @media (max-width: 1040px) {
    display: none;
  }
`;

export const HeaderRight = styled.nav`
  > ul {
    display: flex;
    align-items: center;

    gap: 32px;

    > li {
      cursor: pointer;

      a {
        font-family: 'Sora', sans-serif;
        font-size: 15px;
        font-weight: 400;
        color: ${colors.gray700};
        text-decoration: none;

        display: flex;
        align-items: center;

        &:hover {
          color: ${colors.orange300};
          transition: 0.2s;
        }
      }
    }
  }
`;

export const AppsGridDesktop = styled.li<{ selected?: boolean }>`
  background: ${({ selected }) => (selected ? colors.orange300 : 'none')};

  > a {
    padding: 12px;
  }

  border-radius: 50%;

  svg {
    color: ${({ selected }) => (selected ? 'white' : colors.gray700)};
    animation: ${({ selected }) =>
      selected ? 'color-animation 0.3s ease-in-out' : 'none'};
  }

  &:hover {
    svg {
      color: ${({ selected }) => (selected ? 'white' : colors.orange300)};
      transition: 0.2s;
    }

    background: ${({ selected }) =>
      selected ? colors.orange300 : colors.gray100};

    transition: 0.2s;
  }

  @media (max-width: 1040px) {
    display: none;
  }

  @keyframes bg-animation {
    from {
      background: ${colors.gray100};
    }

    to {
      background: ${colors.orange300};
    }
  }

  @keyframes color-animation {
    from {
      color: ${colors.gray700};
    }

    to {
      color: white;
    }
  }

  animation: ${({ selected }) =>
    selected ? 'bg-animation 0.5s ease-in-out' : 'none'};
`;
