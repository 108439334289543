import Link from 'next/link';
import { useRouter } from 'next/router';
import LinksInformacoesMobile from './LinksInformacoesMobile';
import { useHeader } from '../Provider';

import {
  BurguerMenuContainer,
  MenuMobileLink,
  Menu,
  MenuMobileContainer,
} from './styles';

import { RiArrowDownSLine } from 'react-icons/ri';

const MenuMobileComponent = (): JSX.Element => {
  const {
    menuMobileOpen,
    setMenuMobileOpen,
    toggleMenuMobile,
    toggleAppsMobile,
  } = useHeader();

  const { pathname } = useRouter();

  return (
    <MenuMobileContainer className={menuMobileOpen ? 'open' : 'closed'}>
      <div className="top">
        <BurguerMenuContainer
          isOpen={menuMobileOpen}
          className={menuMobileOpen ? 'open' : 'closed'}
          onClick={toggleMenuMobile}
        >
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </BurguerMenuContainer>
      </div>
      {menuMobileOpen && (
        <Menu isOpen={menuMobileOpen}>
          <Link href="/" passHref>
            <MenuMobileLink selected={pathname === '/'}>
              Página inicial
            </MenuMobileLink>
          </Link>
          <hr />
          <Link href="/a-delps" passHref>
            <MenuMobileLink selected={pathname === '/a-delps'}>
              A Delps
            </MenuMobileLink>
          </Link>
          <hr />
          {/* <LinksInformacoesMobile setMenuOpen={setMenuMobileOpen} /> */}
          {/* <hr /> */}
          <Link href="/cidades-atendidas" passHref>
            <MenuMobileLink selected={pathname === '/cidades-atendidas'}>
              Cidades atendidas
            </MenuMobileLink>
          </Link>
          <hr />
          <Link href="/filiais" passHref>
            <MenuMobileLink selected={pathname === '/filiais'}>
              Filiais
            </MenuMobileLink>
          </Link>
          <hr />
          <Link href="/atendimento" passHref>
            <MenuMobileLink selected={pathname === '/atendimento'}>
              Atendimento
            </MenuMobileLink>
          </Link>
          <hr />

          <Link href="" passHref>
            <MenuMobileLink
              selected={pathname === '/apps'}
              onClick={toggleAppsMobile}
            >
              Serviços online <RiArrowDownSLine size={24} />
            </MenuMobileLink>
          </Link>
        </Menu>
      )}
    </MenuMobileContainer>
  );
};

export default MenuMobileComponent;
