// import Link from 'next/link';

import {
  Container,
  FooterBottomLeft,
  // FooterBottomMiddle,
  FooterBottomRight,
  Wrapper,
} from './styles';

const FooterBottom = (): JSX.Element => {
  return (
    <Container>
      <Wrapper>
        <FooterBottomLeft>© Delps 1996-2021</FooterBottomLeft>
        {/* <FooterBottomMiddle className="desktop">
          <Link href="/termos-de-uso" passHref>
            <a>Termos de Uso</a>
          </Link>
          <hr />
          <Link href="/politicas-de-privacidade" passHref>
            <a>Políticas de privacidade</a>
          </Link>
        </FooterBottomMiddle> */}
        <FooterBottomRight>
          Desenvolvido por{' '}
          <a href="https://hyerdev.com.br" target="_blank" rel="noreferrer">
            Hyerdev
          </a>
        </FooterBottomRight>
      </Wrapper>
    </Container>
  );
};

export default FooterBottom;
