import styled from 'styled-components';

import Header from '@/components/global/Header';
import Footer from '../Footer';

const Layout: React.FC = ({ children }): JSX.Element => {
  return (
    <>
      <Header />
      <MainContainer>{children}</MainContainer>
      <Footer />
    </>
  );
};

const MainContainer = styled.main`
  padding-top: 81px;
`;

export default Layout;
