import styled from 'styled-components';
import { colors } from './GlobalStyle';

export const PageWrapper = styled.div`
  width: 100%;
  max-width: 100vw;

  height: 100%;

  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  background: ${colors.gray100};
`;

export const PageContainer = styled.div`
  width: 100%;
  max-width: 1440px;

  height: fit-content;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const SectionWrapper = styled.div`
  width: 100%;
  max-width: 1360px;

  height: fit-content;

  display: flex;
  justify-content: center;
`;
