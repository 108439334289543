import {
  useMemo,
  useState,
  useContext,
  createContext,
  useCallback,
} from 'react';

const HeaderContext = createContext<any>({});

export const useHeader = (): any => useContext(HeaderContext);

const HeaderProvider: React.FC = ({ children }): JSX.Element => {
  const [menuMobileOpen, setMenuMobileOpen] = useState(false);
  const [menuAppsMobileOpen, setAppsMobileOpen] = useState(false);

  const toggleAppsMobile = useCallback(() => {
    setAppsMobileOpen(!menuAppsMobileOpen);
    setMenuMobileOpen(false);
  }, [setMenuMobileOpen, setAppsMobileOpen, menuAppsMobileOpen]);

  const toggleMenuMobile = useCallback(() => {
    setAppsMobileOpen(false);
    setMenuMobileOpen(!menuMobileOpen);
  }, [menuMobileOpen, setMenuMobileOpen, setAppsMobileOpen]);

  const value = useMemo(() => {
    return {
      menuMobileOpen,
      menuAppsMobileOpen,
      toggleAppsMobile,
      toggleMenuMobile,
      setMenuMobileOpen,
      setAppsMobileOpen,
    };
  }, [
    menuMobileOpen,
    menuAppsMobileOpen,
    toggleAppsMobile,
    toggleMenuMobile,
    setMenuMobileOpen,
    setAppsMobileOpen,
  ]);

  return (
    <HeaderContext.Provider value={value}>{children}</HeaderContext.Provider>
  );
};

export default HeaderProvider;
