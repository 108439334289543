import styled from 'styled-components';

import { colors } from '@/styles/GlobalStyle';

export const Container = styled.div`
  width: 100%;
  max-width: 100vw;
  display: flex;
  justify-content: center;
  background: white;
`;

export const Wrapper = styled.div`
  width: 100%;
  max-width: 1360px;

  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  column-gap: 30px;
  row-gap: 20px;

  padding: 20px 0;

  @media (max-width: 1440px) {
    padding: 20px 40px;
  }

  @media (max-width: 600px) {
    flex-direction: column;
  }

  @media (max-width: 499px) {
    padding-left: 16px;
    padding-right: 16px;
  }
`;

export const FooterBottomLeft = styled.div`
  color: ${colors.gray700};
  font-weight: 300;
  font-size: 12px;
  line-height: 20px;

  @media (max-width: 600px) {
    text-align: center;
    width: 100%;
  }
`;

// export const FooterBottomMiddle = styled.div`
//   display: flex;
//   align-items: center;

//   &.mobile {
//     display: none;
//   }

//   hr {
//     background: ${colors.gray700};
//     margin: 0 25px;
//     height: 20px;
//     width: 1px;
//   }
//   a {
//     color: ${colors.gray700};
//     font-size: 12px;
//     font-family: 'Sora' sans-serif;
//     transition: color 0.2s;
//     font-weight: 400;

//     :hover {
//       color: ${colors.orange300};
//     }
//   }
//   @media (max-width: 600px) {
//     align-self: center;
//     justify-content: center;
//     width: 100%;

//     &.mobile {
//       display: flex;
//     }

//     &.desktop {
//       display: none;
//     }
//   }
// `;

export const FooterBottomRight = styled.p`
  color: ${colors.gray700};
  font-weight: 300;
  font-size: 12px;
  line-height: 20px;

  a {
    font-weight: 600;
    color: ${colors.gray700};
    transition: color 0.2s;

    :hover {
      color: ${colors.orange300};
    }
  }

  @media (max-width: 650px) {
    text-align: center;
    width: 100%;
  }
`;
