import { colors } from '@/styles/GlobalStyle';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  max-width: 1360px;
`;

export const Wrapper = styled.div`
  width: 100%;
  max-width: 885px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 40px;

  padding-top: 40px;
  padding-bottom: 75px;

  @media (max-width: 1440px) {
    padding-left: 40px;
    padding-right: 40px;
  }

  @media (max-width: 675px) {
    max-width: 400px;
  }

  @media (max-width: 499px) {
    padding-left: 16px;
    padding-right: 16px;
  }
`;

export const Columns = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 715px;
  gap: 50px;
  flex-wrap: wrap;

  @media (max-width: 1440px) {
    max-width: 635px;
  }

  @media (max-width: 675px) {
    max-width: 300px;
  }
  @media (max-width: 499px) {
    flex-direction: column;
  }
`;

export const FooterTopColumn = styled.div`
  p {
    font-family: 'Open Sans', sans-serif;
    font-size: 10px;
    font-weight: 400;
    line-height: 16px;
    text-transform: uppercase;
    color: ${colors.gray300};
    margin: 0;
  }

  ul {
    margin-top: 19px;

    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  a {
    color: white;
    font-family: 'Sora', sans-serif;
    font-size: 12px;
    line-height: 20px;
    transition: color 0.2s;

    :hover {
      color: ${colors.orange300};
    }
  }
`;

export const SocialMedia = styled(FooterTopColumn)`
  ul {
    flex-direction: row;
  }
`;
