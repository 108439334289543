import Link from 'next/link';
import { useRouter } from 'next/router';

import MenuMobile from './MenuMobile';
// import LinksInformacoes from './LinksInformacoes';
import AppsMobile from './AppsMobile';
import Provider from './Provider';

import {
  HeaderContainer,
  HeaderWrapper,
  HeaderLeft,
  HeaderItem,
  HeaderRight,
  AppsGridDesktop,
} from './styles';

import { BsGrid3X3GapFill } from 'react-icons/bs';

import Logo from '../../../images/components/Header/logo.svg';

const Header = (): JSX.Element => {
  const { pathname } = useRouter();

  return (
    <HeaderContainer>
      <HeaderWrapper>
        <Provider>
          <MenuMobile />
          <HeaderLeft>
            <Link href="/" passHref>
              <a aria-label="Logo da Delps">
                <Logo />
              </a>
            </Link>
            <ul>
              <HeaderItem selected={pathname === '/'}>
                <Link href="/">Página Inicial</Link>
              </HeaderItem>
              <HeaderItem selected={pathname === '/a-delps'}>
                <Link href="/a-delps">A Delps</Link>
              </HeaderItem>
              <HeaderItem selected={pathname === '/filiais'}>
                <Link href="/filiais">Filiais</Link>
              </HeaderItem>
              <HeaderItem selected={pathname === '/cidades-atendidas'}>
                <Link href="/cidades-atendidas">Cidades Atendidas</Link>
              </HeaderItem>
            </ul>
          </HeaderLeft>
          <HeaderRight>
            <ul>
              <HeaderItem selected={pathname === '/rastreamento'}>
                <Link href="/rastreamento">Rastreamento</Link>
              </HeaderItem>
              <HeaderItem selected={pathname === '/atendimento'}>
                <Link href="/atendimento">Atendimento</Link>
              </HeaderItem>
              <AppsGridDesktop selected={pathname.includes('/rastreamento')}>
                <Link href="/rastreamento">
                  <a className="apps-grid" aria-label="Aplicativos da Delps">
                    <BsGrid3X3GapFill size={25} />
                  </a>
                </Link>
              </AppsGridDesktop>
              <AppsMobile />
            </ul>
          </HeaderRight>
        </Provider>
      </HeaderWrapper>
    </HeaderContainer>
  );
};

export default Header;
